import React from 'react';
import './App.css';
import { Switch, Route } from 'react-router-dom';
import Login from './Components/login.jsx';
import Dashboard from './Components/Pages/dashboard.jsx';
import Users from './Components/Pages/Users/users.jsx';
import Subscriptions from './Components/Pages/Users/subscriptions.jsx';
import Usershistory from './Components/Pages/Users/history.jsx';
import Usersinfo from './Components/Pages/Users/usersinfo.jsx';
import ECU from './Components/Pages/ECU/ecu.jsx';
import Scripts from './Components/Pages/ECU/scripts.jsx';
import History from './Components/Pages/ECU/history.jsx';
import Requests from './Components/Pages/Requests/requests.jsx';
import Tickets from './Components/Pages/Requests/tickets.jsx';
import Chat from './Components/Pages/Requests/chat.jsx';
import Email from './Components/Pages/Requests/email.jsx';
import Status from './Components/Pages/Requests/status.jsx';
import Pricing from './Components/Pages/ECU/pricing.jsx';
import PrivateRoute from './Components/private.jsx';
import UserDetails from './Components/Pages/Users/userdetails.jsx';
import Solutions from './Components/Pages/ECU/solution.jsx';
import Admins from './Components/Pages/Admins/addadmins.jsx';
import PrivateAdmin from './Components/privateadmin.jsx';
import Error from './Components/404.jsx';

function App() {
  return (
    <div className="app">
      <Switch>
        <Route
          exact path="/"
          component={Login}
        />
        <Route
          exact path="/404"
          component={Error}
        />
        <PrivateRoute
          exact path="/dashboard"
          component={Dashboard}
        />
        <PrivateRoute
          exact path="/manage-users"
          component={Users}
        />
        <PrivateRoute
          exact path="/subscriptions"
          component={Subscriptions}
        />
        <PrivateRoute
          exact path="/history"
          component={Usershistory}
        />
        <PrivateRoute
          exact path="/users-info"
          component={Usersinfo}
        />
        <PrivateRoute
          exact path="/ecu"
          component={ECU}
        />
        <PrivateRoute
          exact path="/scripts"
          component={Scripts}
        />
        <PrivateRoute
          exact path="/scripts-history"
          component={History}
        />
        <PrivateRoute
          exact path="/requests"
          component={Requests}
        />
        <PrivateRoute
          exact path="/tickets"
          component={Tickets}
        />
        <PrivateRoute
          exact path="/chat"
          component={Chat}
        />
        <PrivateRoute
          exact path="/email"
          component={Email}
        />
        <PrivateRoute
          exact path="/status"
          component={Status}
        />
        <PrivateRoute
          exact path="/pricing"
          component={Pricing}
        />
        <PrivateRoute
          exact path="/user-details/:id"
          component={UserDetails}
        />
        <PrivateAdmin
          exact path="/manage-admins"
          component={Admins}
        />
        <PrivateRoute
          exact path="/solutions"
          component={Solutions}
        />
      </Switch>
    </div>
  );
}

export default App;
