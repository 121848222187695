import React, { useState, useEffect } from 'react';
import {
  MDBRow, MDBCol, MDBBtn, MDBSpinner,
} from 'mdb-react-ui-kit';
import axios from 'axios';
import Cookies from 'js-cookie';
import Sidebar from '../../parts/sidebar.jsx';

const Status = () => {
  const [status, setStatus] = useState();
  const [submit, setSubmit] = useState(false);

  const getSupport = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/Admin/getsupport?id=${Cookies.get('adminId')}`, {
      });
      const responseData = response.data;

      setStatus(responseData.status);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  useEffect(() => {
    getSupport();
  }, []);

  const support = async (supportStatus) => {
    setSubmit(true);
    try {
      const data = {
        status: supportStatus,
      };
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/Admin/support?id=${Cookies.get('adminId')}`, data, {
      });
      const responseData = response.data;

      if (responseData.message === 'updated') {
        setSubmit(false);
        getSupport();
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Sidebar />
      <div className='main-content'>
        <h3
          style={{
            color: 'black', marginLeft: '3%', fontFamily: 'bahnschrift', marginTop: '3%',
          }}
        >Status</h3>
        {status === 1 ? (
          <p style={{ marginLeft: '3%' }}>You are Online Currently</p>
        ) : (
          <p style={{ marginLeft: '3%' }}>You are Offline Currently</p>
        )}
        <MDBRow style={{ margin: '20px' }}>
            <MDBCol md={8}>
              {status === 1 ? (
                <MDBBtn
                  style={{ backgroundColor: 'green' }} onClick={() => {
                    support(false);
                  }}
                >
                  {submit ? (
                    <MDBSpinner></MDBSpinner>
                  ) : (
                    <span>Close Now</span>
                  )}
                </MDBBtn>
              ) : (
                <MDBBtn
                  style={{ backgroundColor: 'red' }} onClick={() => {
                    support(true);
                  }}
                >{submit ? (
                  <MDBSpinner></MDBSpinner>
                ) : (
                  <span>Active Now</span>
                )}</MDBBtn>
              )}
            </MDBCol>
        </MDBRow>
        </div>
    </div>
  );
};

export default Status;
