import React from 'react';

const Error = () => (
    <div
      style={{
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', color: 'white',
      }}
    >
      <h1 style={{ fontSize: '60px' }}>404</h1>
      <p style={{ fontSize: '30px' }}>Page Not Found</p>
    </div>
);

export default Error;
