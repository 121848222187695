import React, { useEffect, useState } from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBSpinner,
  MDBBtn,
} from 'mdb-react-ui-kit';
import axios from 'axios';
import Sidebar from '../../parts/sidebar.jsx';

const Pricing = () => {
  const [pricewt, setProcewt] = useState(0);
  const [pricewot, setPricewot] = useState(0);
  const [submit, setSubmit] = useState(false);
  const [priceSubmit, setPriceSubmit] = useState(false);
  const [precre, setPrecre] = useState();
  const [prestage, setPrestage] = useState();
  const [norCre, setNorcre] = useState();
  const [norStage, setNorstage] = useState();
  const [cusCre, setCustcre] = useState();
  const [cusStage, setCustomStage] = useState();

  const getPricing = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/Admin/getPricing`,
        {},
      );
      const responseData = response.data;
      setProcewt(responseData.data[0].pricewt);
      setPricewot(responseData.data[0].pricewot);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const getPlans = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/Admin/getPlans`,
        {
          headers: {
            'Content-Type': 'application/json',
            'api-key': process.env.REACT_APP_API_KEY,
          },
        },
      );
      const responseData = response.data;
      setPrecre(responseData.data[0].credits);
      setPrestage(responseData.data[0].stage);
      setNorcre(responseData.data[1].credits);
      setNorstage(responseData.data[1].stage);
      setCustcre(responseData.data[2].credits);
      setCustomStage(responseData.data[2].stage);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  useEffect(() => {
    getPricing();
    getPlans();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmit(true);
    const data = {
      wtax: pricewt,
      wotax: pricewot,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/Admin/updatePricing`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            'api-key': process.env.REACT_APP_API_KEY,
          },
        },
      );
      const responseData = response.data;
      if (responseData.message === 'updated') {
        setSubmit(false);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const handlePricingSubmit = async (event) => {
    event.preventDefault();
    setPriceSubmit(true);
    const data = {
      preCre: precre,
      preStage: prestage,
      norCre,
      norStage,
      cusCre,
      cusStage,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/Admin/updatePlan`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            'api-key': process.env.REACT_APP_API_KEY,
          },
        },
      );
      const responseData = response.data;
      if (responseData.message === 'updated') {
        setPriceSubmit(false);
      }
    } catch (error) {
      console.error('Error:', error.message);
      setPriceSubmit(false);
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Sidebar />
      <div className="main-content">
        <h3
          style={{
            color: 'black',
            marginLeft: '2%',
            fontFamily: 'bahnschrift',
          }}
        >
          Manage Prices
        </h3>

        <MDBRow style={{ margin: '20px' }}>
          <MDBCol md={12}>
            <MDBCard
              className="text-center label-left"
              style={{ margin: '30px', backgroundColor: '#2d353c' }}
            >
              <MDBCardBody>
                <MDBCardText>
                  <form onSubmit={handlePricingSubmit}>
                    <MDBRow
                      style={{
                        margin: '0px 30px -10px',
                        borderBottom: '1px solid #fff',
                        paddingBottom: '20px',
                      }}
                    >
                      <MDBCol md={4}></MDBCol>
                      <MDBCol md={4} style={{ fontWeight: 'bold' }}>
                        Solution
                      </MDBCol>
                      <MDBCol md={4} style={{ fontWeight: 'bold' }}>
                        Tuning
                      </MDBCol>
                    </MDBRow>

                    <MDBRow
                      style={{
                        margin: '30px 30px -10px',
                        borderBottom: '1px solid #fff',
                        paddingBottom: '20px',
                      }}
                    >
                      <MDBCol
                        md={4}
                        style={{ textAlign: 'left', fontWeight: 'bold' }}
                      >
                        Premium User
                      </MDBCol>
                      <MDBCol md={4}>
                        <MDBInput
                          style={{ color: '#fff', textAlign: 'center' }}
                          id="credits"
                          type="text"
                          value={precre}
                          onChange={(e) => {
                            setPrecre(e.target.value);
                          }}
                        />
                      </MDBCol>
                      <MDBCol md={4}>
                        <MDBInput
                          style={{ color: '#fff', textAlign: 'center' }}
                          id="stage"
                          type="text"
                          value={prestage}
                          onChange={(e) => {
                            setPrestage(e.target.value);
                          }}
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow
                      style={{
                        margin: '30px 30px -10px',
                        borderBottom: '1px solid #fff',
                        paddingBottom: '20px',
                      }}
                    >
                      <MDBCol
                        md={4}
                        style={{ textAlign: 'left', fontWeight: 'bold' }}
                      >
                        Normal User
                      </MDBCol>
                      <MDBCol md={4}>
                        <MDBInput
                          style={{ color: '#fff', textAlign: 'center' }}
                          id="credits"
                          type="text"
                          value={norCre}
                          onChange={(e) => {
                            setNorcre(e.target.value);
                          }}
                        />
                      </MDBCol>
                      <MDBCol md={4}>
                        <MDBInput
                          style={{ color: '#fff', textAlign: 'center' }}
                          id="stage"
                          type="text"
                          value={norStage}
                          onChange={(e) => {
                            setNorstage(e.target.value);
                          }}
                        />
                      </MDBCol>
                    </MDBRow>

                    <MDBRow
                      style={{
                        margin: '30px 30px -10px',
                        paddingBottom: '20px',
                      }}
                    >
                      <MDBCol
                        md={4}
                        style={{ textAlign: 'left', fontWeight: 'bold' }}
                      >
                        Custom User
                      </MDBCol>
                      <MDBCol md={4}>
                        <MDBInput
                          style={{ color: '#fff', textAlign: 'center' }}
                          id="credits"
                          type="text"
                          value={cusCre}
                          onChange={(e) => {
                            setCustcre(e.target.value);
                          }}
                        />
                      </MDBCol>
                      <MDBCol md={4}>
                        <MDBInput
                          style={{ color: '#fff', textAlign: 'center' }}
                          id="stage"
                          type="text"
                          value={cusStage}
                          onChange={(e) => {
                            setCustomStage(e.target.value);
                          }}
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow
                      style={{
                        margin: '30px 30px -10px',
                        paddingBottom: '20px',
                      }}
                    >
                      <MDBCol
                        md={4}
                        style={{ textAlign: 'left', fontWeight: 'bold' }}
                      ></MDBCol>
                      <MDBCol md={8}>
                        <MDBBtn style={{ marginTop: '0px', width: '100%' }}>
                          {priceSubmit ? (
                            <MDBSpinner></MDBSpinner>
                          ) : (
                            <span>Update</span>
                          )}
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </form>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        <MDBRow style={{ margin: '20px' }}>
          <MDBCol md={12}>
            <MDBCard
              className="text-center label-left"
              style={{ margin: '30px', backgroundColor: '#2d353c' }}
            >
              <MDBCardBody>
                <MDBCardText>
                  <form onSubmit={handleSubmit}>
                    <MDBRow style={{ margin: '30px' }}>
                      <MDBCol
                        md={2}
                        style={{ textAlign: 'left', fontWeight: 'bold' }}
                      ></MDBCol>
                      <MDBCol md={4}>
                        <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
                          Price per Credit (incl. VAT)
                        </p>
                        <MDBInput
                          style={{ color: '#fff', textAlign: 'center' }}
                          id="form1"
                          type="text"
                          value={pricewt}
                          onChange={(e) => {
                            setProcewt(e.target.value);
                          }}
                          required
                        />
                      </MDBCol>
                      <MDBCol md={4}>
                        <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
                          Price per Credit
                        </p>
                        <MDBInput
                          style={{ color: '#fff', textAlign: 'center' }}
                          id="form1"
                          type="text"
                          value={pricewot}
                          onChange={(e) => {
                            setPricewot(e.target.value);
                          }}
                          required
                        />
                      </MDBCol>
                      <MDBCol
                        md={2}
                        style={{ textAlign: 'left', fontWeight: 'bold' }}
                      ></MDBCol>
                    </MDBRow>
                    <MDBRow style={{ margin: '30px' }}>
                      <MDBCol md={2} style={{ marginTop: '-15px' }}></MDBCol>
                      <MDBCol md={8} style={{ marginTop: '-15px' }}>
                        <MDBBtn style={{ marginTop: '0px', width: '100%' }}>
                          {submit ? (
                            <MDBSpinner></MDBSpinner>
                          ) : (
                            <span>Update</span>
                          )}
                        </MDBBtn>
                      </MDBCol>
                      <MDBCol
                        md={2}
                        style={{ textAlign: 'left', fontWeight: 'bold' }}
                      ></MDBCol>
                    </MDBRow>
                  </form>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </div>
    </div>
  );
};

export default Pricing;
