import { api } from './api.config';

const ecuService = api.injectEndpoints({
  endpoints: (builder) => ({
    getCars: builder.query({
      query: () => ({
        url: '/Admin/getCars',
        method: 'GET',
      }),
      transformResponse: (response) => response.data,
    }),
    getControllers: builder.query({
      query: () => ({
        url: '/Admin/getControllers',
        method: 'GET',
      }),
      transformResponse: (response) => response.data,
    }),
    addCar: builder.mutation({
      query: ({ data }) => ({
        url: '/Admin/addmake',
        method: 'POST',
        body: data,
      }),
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(
          api.util.updateQueryData('getCars', undefined, (draft) => {
            if (data.message === 'added') {
              draft.unshift(data);
            }
          }),
        );
      },
    }),
  }),
});

export const { useGetCarsQuery, useGetControllersQuery, useAddCarMutation } = ecuService;
