import React, { useEffect, useState } from 'react';
import {
  MDBTable, MDBTableHead, MDBTableBody, MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBRow,
  MDBCol,
} from 'mdb-react-ui-kit';
import axios from 'axios';
import Sidebar from '../../parts/sidebar.jsx';

const Scripts = () => {
  const [scripts, getScripts] = useState([]);

  const getDetails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/Admin/getScripts`, {
        headers: {
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data;

      getScripts(responseData.data);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  const deleteScript = async (id) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/Admin/deleteScript?id=${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data;
      if (responseData.message === 'deleted') {
        getDetails();
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Sidebar />
      <div className='main-content'>
        <h3 style={{ color: 'black', marginLeft: '2%', fontFamily: 'bahnschrift' }}>Scripts</h3>
          <MDBRow className='row-cols-12 row-cols-md-12 g-12' style={{ margin: '30px' }}>
          <MDBCol>
            <MDBCard className='h-100'>
              <MDBCardBody>
                <MDBCardText>

        <div
          style={{
            height: '550px', overflowY: 'auto', overflowX: 'auto', maxWidth: '100%',
          }}
        >
        <MDBTable align='middle' class='scripts_table'>
          <MDBTableHead>
            <tr>
              <th scope='col' >Car</th>
              <th scope='col' >Controller</th>
              <th scope='col' >Filename</th>
              <th scope='col' >Credits</th>
              <th scope='col' >Dated</th>
              <th scope='col' >Script</th>
              <th scope='col' >Delete</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {scripts.map((item, index) => (
              <tr key={index}>
              <td >
                    {item.car}
              </td>
              <td >
                {item.controller}
              </td>
              <td >
                {item.scriptfile}
              </td>
              <td >
                {item.credits}
              </td>
              <td >
                {new Date(item.dated).toLocaleString()}
              </td>
              <td >
                <a href={`${process.env.REACT_APP_BACKEND_URL}/scripts/${item.car}/${item.controller}/${item.scriptfile}`}><i className="fa fa-download" aria-hidden="true"></i></a>
              </td>
              <td >
                  <i
                    className='fa fa-trash' style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (window.confirm('Are you sure you want to Delete?')) {
                        deleteScript(item.Id);
                      }
                    }}
                  ></i>
              </td>
            </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
        </div>

                    </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
      </MDBRow>

      </div>
    </div>
  );
};

export default Scripts;
