import React, { useEffect, useState } from 'react';
import {
  Form, Button,
} from 'react-bootstrap';
import axios from 'axios';
import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBRow, MDBCol,
  MDBSpinner,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from 'mdb-react-ui-kit';
import { toast, ToastContainer } from 'react-toastify';
import Sidebar from '../../parts/sidebar.jsx';
import 'react-toastify/dist/ReactToastify.css';

const Admins = () => {
  const [admins, setAdmins] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [file, setFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [updatesubmit, setUpdatesubmit] = useState(false);
  const [id, setId] = useState('');

  const getAdmin = async () => {
    console.log('getAdmin');
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/Admin/getadmins`, {
        headers: {
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data;
      setAdmins(responseData.data);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  useEffect(() => {
    getAdmin();
  }, []);

  const showSuccessMessage = () => {
    toast.success('Admin Added', {
      position: 'top-center',
    });
  };

  const deleteAdmin = async (adminId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/Admin/deleteAdmin?id=${adminId}`, {
      });
      const responseData = response.data;
      if (responseData.message === 'deleted') {
        getAdmin();
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);
    const form = e.target;
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('avatar', file);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/Admin/addadmin`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'api-key': process.env.REACT_APP_API_KEY,
        },
      });
      const { data } = response;
      if (data.message === 'added') {
        form.reset();
        setSubmit(false);
        showSuccessMessage();
      }
    } catch (error) {
      console.error('Error:', error.message);
      setSubmit(false);
    }
  };

  const showUpdateMessage = () => {
    toast.success('Admin Updated', {
      position: 'top-center',
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setUpdatesubmit(true);
    const form = e.target;
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('avatar', file);
    formData.append('id', id);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/Admin/updateadmin`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'api-key': process.env.REACT_APP_API_KEY,
        },
      });
      const { data } = response;
      if (data.message === 'update') {
        form.reset();
        setUpdatesubmit(false);
        setShowModal(false);
        getAdmin();
        showUpdateMessage();
      }
    } catch (error) {
      console.error('Error:', error.message);
      setUpdatesubmit(false);
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <ToastContainer />
      <Sidebar />
      <div className='main-content'>
        <h3 style={{ color: 'black', marginLeft: '2%', fontFamily: 'bahnschrift' }}>Manage Admins</h3>

          <MDBRow style={{ margin: '20px' }}>
            <MDBCol md={6}>
                <MDBCard className="text-center label-left" style={{ margin: '30px', backgroundColor: '#2d353c' }}>
                <MDBCardBody>
                <MDBCardText>

              <form
                style={{
                  textAlign: 'left',
                }}
                onSubmit={handleSubmit}
                id="emailsender"
                method="post"
              >

                    <Form.Group className="mb-3" controlId="formName">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        placeholder="Admin Name"
                        type="name"
                        name="name"
                        id="name"
                        required
                        value={name}
                        onChange={(e) => { setName(e.target.value); }}
                        style={{ backgroundColor: '#F9F9F9' }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formName">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        placeholder="Enter Email"
                        type="email"
                        name="adminemail"
                        id="email"
                        required
                        value={email}
                        onChange={(e) => { setEmail(e.target.value); }}
                        style={{ backgroundColor: '#F9F9F9' }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formEmail">
                      <Form.Label>
                        Password
                      </Form.Label>
                      <Form.Control
                        id="password"
                        name="password"
                        type="password"
                        required
                        value={password}
                        onChange={(e) => { setPassword(e.target.value); }}
                        placeholder="Enter Password"
                        style={{ backgroundColor: '#F9F9F9' }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formEmail">
                      <Form.Label>
                        Avatar
                      </Form.Label>
                      <Form.Control
                        id="avatar"
                        name="avatar"
                        type="file"
                        required
                        onChange={(e) => { setFile(e.target.files[0]); }}
                        style={{ backgroundColor: '#F9F9F9' }}
                      />
                    </Form.Group>

                    <Button
                      type="submit"
                      style={{
                        backgroundColor: 'black',
                        color: 'white',
                        border: 'none',
                        fontWeight: 'bold',
                        width: '100%',
                      }}
                      block
                    >
                      {submit ? (
                        <MDBSpinner></MDBSpinner>
                      ) : (
                        <span>Add Admin</span>
                      )}
                    </Button>
              </form>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
            <MDBCol md={6}>
                    <MDBCard className="text-center label-left" style={{ margin: '30px', backgroundColor: '#2d353c' }}>
                    <MDBCardBody>
                <MDBCardText>
                {admins.map((item, index) => (
                    <div key={index}>
                        <div
                          className="d-flex align-items-center justify-content-between"
                          style={{ marginBottom: '10px' }}
                        >
                        <div className="d-flex align-items-center">
                            <img
                              src={`${process.env.REACT_APP_BACKEND_URL}/files/${item.image}`}
                              style={{
                                width: '45px',
                                height: '45px',
                                borderRadius: '50px',
                                marginRight: '10px',
                              }}
                              alt=""
                            />
                            <div>
                            <a href="#" style={{ textDecoration: 'none' }}>
                                <div
                                  style={{
                                    fontSize: '17px',
                                    fontWeight: 'bold',
                                    color: '#fff',
                                    textAlign: 'left',
                                  }}
                                >
                                {item.name}
                                </div>
                            </a>
                            <div
                              style={{
                                fontSize: '13px',
                                textAlign: 'left',
                              }}
                            >
                                {item.email}
                            </div>
                            </div>
                        </div>

                        {/* Update and Delete Icons */}
                        <div>
                            <i
                              className="fa fa-edit"
                              style={{ cursor: 'pointer', marginRight: '10px' }}
                              onClick={() => {
                                setEmail(item.email);
                                setName(item.name);
                                setId(item.Id);
                                setShowModal(true);
                              }}
                            ></i>
                            <i
                              className="fa fa-trash"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                if (window.confirm('Are you sure you want to delete?')) {
                                  deleteAdmin(item.Id);
                                }
                              }}
                            ></i>
                        </div>
                        </div>
                        <div
                          style={{
                            width: '100%',
                            height: '1px',
                            backgroundColor: '#DFE3EF',
                            marginBottom: '10px',
                          }}
                        ></div>
                    </div>
                ))}

              </MDBCardText>
              </MDBCardBody>
            </MDBCard>
              </MDBCol>
            </MDBRow>
      </div>

      <MDBModal
        show={showModal} onHide={() => setShowModal(false)}
        tabIndex='-1'
      >
        <MDBModalDialog centered size="md">
            <MDBModalContent style={{ color: 'black' }}>
            <MDBModalBody>
            <center><h3 style={{ color: 'black', fontFamily: 'bahnschrift', marginTop: '15px' }}>Update Admin</h3></center>

            <center>
                <div>
                    <form onSubmit={handleUpdate}>
                        <Form.Group className="mb-3" controlId="formName">
                        <Form.Control
                          placeholder="Admin Name"
                          type="name"
                          name="name"
                          id="name"
                          required
                          value={name}
                          onChange={(e) => { setName(e.target.value); }}
                          style={{ backgroundColor: '#F9F9F9' }}
                        />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formName">

                        <Form.Control
                          placeholder="Enter Email"
                          type="email"
                          name="adminemail"
                          id="email"
                          required
                          value={email}
                          onChange={(e) => { setEmail(e.target.value); }}
                          style={{ backgroundColor: '#F9F9F9' }}
                        />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Control
                          id="avatar"
                          name="avatar"
                          type="file"
                          required
                          onChange={(e) => { setFile(e.target.files[0]); }}
                          style={{ backgroundColor: '#F9F9F9' }}
                        />
                        </Form.Group>
                        <Button
                          type="submit"
                          style={{
                            backgroundColor: 'black',
                            color: 'white',
                            border: 'none',
                            fontWeight: 'bold',
                            width: '100%',
                          }}
                          block
                        >
                            {updatesubmit ? (
                                <MDBSpinner></MDBSpinner>
                            ) : (
                                <span>Update Admin</span>
                            )}
                        </Button>
                    </form>
                </div>
            </center>
            </MDBModalBody>
            </MDBModalContent>
        </MDBModalDialog>
        </MDBModal>
    </div>
  );
};

export default Admins;
