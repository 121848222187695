import React, { useState } from 'react';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBCollapse,
  MDBListGroup,
  MDBListGroupItem,
} from 'mdb-react-ui-kit';
import './sidebar.css';
import Cookies from 'js-cookie';

export default function App() {
  const [showShow, setShowShow] = useState(false);

  const toggleShow = () => setShowShow(!showShow);

  return (
    <>
      <MDBCollapse
        show={showShow} tag="nav"
        className="d-lg-block sidebar" style={{ backgroundColor: '#2d353c' }}
      >
        <div className="position-sticky">
          <MDBListGroup className="mx-3 mt-4">
            <p
              style={{
                marginLeft: '23px', marginBottom: '-2px', color: '#ffffff', fontSize: '15px',
              }}
            >General</p>
              <MDBListGroupItem
                tag='a' href='/dashboard'
                className='border-0' style={{ backgroundColor: '#2d353c', color: 'white', fontSize: '15px' }}
              >
                <MDBIcon
                  fas icon="home me-3"
                  style={{ color: '#ffffff', textTransform: 'uppercase' }}
                />
                Dashboard
              </MDBListGroupItem>

            <p
              style={{
                marginLeft: '23px', marginBottom: '-2px', marginTop: '25px', color: '#ffffff', fontSize: '15px',
              }}
            >Functions</p>
              <MDBListGroupItem
                tag='a' href='/ecu'
                action className='border-0'
                style={{ backgroundColor: '#2d353c', color: 'white', fontSize: '15px' }}
              >
              <MDBIcon
                fas icon="plus"
                className="me-3" style={{ color: '#ffffff', textTransform: 'uppercase' }}
              />
                Add Solution
              </MDBListGroupItem>

              <MDBListGroupItem
                tag='a' href='/solutions'
                action className='border-0'
                style={{ backgroundColor: '#2d353c', color: 'white', fontSize: '15px' }}
              >
              <MDBIcon
                fas icon="list me-3"
                style={{ color: '#ffffff', textTransform: 'uppercase' }}
              />
                Solutions
              </MDBListGroupItem>

              <MDBListGroupItem
                tag='a' href='/scripts'
                action className='border-0'
                style={{ backgroundColor: '#2d353c', color: 'white', fontSize: '15px' }}
              >
              <MDBIcon
                fas icon="code me-3"
                style={{ color: '#ffffff', textTransform: 'uppercase' }}
              />
                Scripts
              </MDBListGroupItem>

            <p
              style={{
                marginLeft: '23px', marginBottom: '-2px', marginTop: '25px', color: '#ffffff', fontSize: '15px',
              }}
            >Management</p>
              <MDBListGroupItem
                tag='a' href='/users-info'
                action className='border-0'
                style={{ backgroundColor: '#2d353c', color: 'white', fontSize: '15px' }}
              >
              <MDBIcon
                fas icon="user-circle me-3"
                style={{ color: '#ffffff', textTransform: 'uppercase' }}
              />
                Users
              </MDBListGroupItem>

                <MDBListGroupItem
                  tag='a' href='/manage-admins'
                  action className='border-0'
                  style={{ backgroundColor: '#2d353c', color: 'white', fontSize: '15px' }}
                >
                <MDBIcon
                  fas icon="user-secret me-3"
                  style={{ color: '#ffffff', textTransform: 'uppercase' }}
                />
                  Admins
                </MDBListGroupItem>

              <MDBListGroupItem
                tag='a' href='/pricing'
                action className='border-0'
                style={{ backgroundColor: '#2d353c', color: 'white', fontSize: '15px' }}
              >
              <MDBIcon
                fas icon="coins me-3"
                style={{ color: '#ffffff', textTransform: 'uppercase' }}
              />
              Prices
              </MDBListGroupItem>

            <p
              style={{
                marginLeft: '23px', marginBottom: '-2px', marginTop: '25px', color: '#ffffff', fontSize: '15px',
              }}
            >Notifications</p>
              <MDBListGroupItem
                tag='a' href='/requests'
                action className='border-0'
                style={{ backgroundColor: '#2d353c', color: 'white', fontSize: '15px' }}
              >
              <MDBIcon
                fas icon="comment me-3"
                style={{ color: '#ffffff', textTransform: 'uppercase' }}
              />
                Requests
              </MDBListGroupItem>

              <MDBListGroupItem
                tag='a' href='/email'
                action className='border-0'
                style={{ backgroundColor: '#2d353c', color: 'white', fontSize: '15px' }}
              >
              <MDBIcon
                fas icon="rss me-3"
                style={{ color: '#ffffff', textTransform: 'uppercase' }}
              />
                Broadcast
              </MDBListGroupItem>

            <p
              style={{
                marginLeft: '23px', marginBottom: '-2px', marginTop: '25px', color: '#ffffff', fontSize: '15px',
              }}
            >Status</p>
              <MDBListGroupItem
                tag='a' href='/status'
                action className='border-0'
                style={{ backgroundColor: '#2d353c', color: 'white', fontSize: '15px' }}
              >
              <MDBIcon
                fas icon="bell me-3"
                style={{ color: '#ffffff', textTransform: 'uppercase' }}
              />
                Support Status
              </MDBListGroupItem>
              <MDBListGroupItem
                tag='a' onClick={() => {
                  if (window.confirm('Are you sure you want to logout?')) {
                    Cookies.remove('adtoken');
                    Cookies.remove('adminEmail');
                    Cookies.remove('adminId');
                    window.location.href = '/';
                  }
                }}
                action className='border-0'
                style={{ backgroundColor: '#2d353c', color: 'white', fontSize: '15px' }}
              >
              <MDBIcon
                fas icon="sign-out-alt me-3"
                style={{ color: '#ffffff', textTransform: 'uppercase' }}
              />
                Logout
              </MDBListGroupItem>
          </MDBListGroup>
        </div>
      </MDBCollapse>

      <MDBNavbar expand='lg' style={{ backgroundColor: 'rgb(45, 53, 60)', boxShadow: '0 4px 12px 0 rgba(0,0,0,.07),0 2px 4px rgba(0,0,0,.05)' }}>
        <MDBContainer fluid>
          <MDBNavbarNav className="d-flex flex-row align-items-center w-auto">
            <MDBNavbarToggler
              type='button'
              aria-label='Toggle navigation'
              onClick={toggleShow}
            >
              <MDBIcon icon='bars' fas />
            </MDBNavbarToggler>
            <MDBNavbarBrand href='#'>
              <img
                src="./Assets/logo.png" alt=""
                style={{ height: '2rem' }}
              />
            </MDBNavbarBrand>

          </MDBNavbarNav>
          <MDBNavbarNav className="d-flex flex-row justify-content-end w-auto">
            <MDBNavbarItem className='me-3 me-lg-0 d-flex align-items-center'>
            </MDBNavbarItem>
            <MDBNavbarItem className='me-3 me-lg-0 d-flex align-items-center'>
            </MDBNavbarItem>
          </MDBNavbarNav>
        </MDBContainer>
      </MDBNavbar>
    </>
  );
}
