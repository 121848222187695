import React, { useState, useEffect, useRef } from 'react';
import {
  MDBRow, MDBCol, MDBInput, MDBBtn, MDBIcon,
} from 'mdb-react-ui-kit';
import axios from 'axios';
import Cookies from 'js-cookie';
import Sidebar from '../../parts/sidebar.jsx';

const Chat = () => {
  const messagesContainerRef = useRef(null);
  const shouldAutoScrollRef = useRef(true);

  const [selected, setSelected] = useState(''); // eslint-disable-line
  const [selectedId, setSelectedId] = useState('');
  const [TicketM, setTicketParam] = useState('');
  const urlParams = new URLSearchParams(window.location.search);

  // Get individual parameter values
  const IdParam = urlParams.get('id');
  const NameParam = urlParams.get('name');
  const TicketParam = urlParams.get('ticketno');

  useEffect(() => {
    setSelected(NameParam);
    setTicketParam(TicketParam);
    setSelectedId(IdParam);
  }, []); // eslint-disable-line

  const handleScrollDown = () => {
    const container = messagesContainerRef.current;

    if (shouldAutoScrollRef.current) {
      container.scrollTop = container.scrollHeight;
    }
  };

  async function getContacts() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Dealer/getMessages`,
        {
          method: 'GET',
          headers: {
            'api-key': process.env.REACT_APP_API_KEY,
          },
        },
      );

      const data = await response.json();
      const uniqueKeys = ['Id', 'ticketNo'];
      const uniqueIdSet = new Set();

      const uniqueIdData = data.data.filter((item) => {
        const compositeIdValue = uniqueKeys.map((key) => item[key]).join('-');
        if (!uniqueIdSet.has(compositeIdValue)) {
          uniqueIdSet.add(compositeIdValue);
          return true;
        }
        return false;
      });
      setContacts(uniqueIdData); // eslint-disable-line
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const [messages, setMessages] = useState([]);

  async function getMessages(selectedId, ticket) { // eslint-disable-line
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Dealer/getMessages?requestId=${ticket}`,
        {
          method: 'GET',
          headers: {
            'api-key': process.env.REACT_APP_API_KEY,
          },
        },
      );

      const data = await response.json();
      const filteredMessages = data.data.filter((item) => (item.senderId === +(selectedId) || item.recieverId === +(selectedId)) && (item.ticketNo === +(ticket)));

      setMessages((prevMessages) => {
        if (prevMessages.length !== filteredMessages.length) {
          setTimeout(() => {
            handleScrollDown();
          }, 100);
        }

        return data.data;
      });
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const [contact, setContacts] = useState([]); // eslint-disable-line
  const [file, setFile] = useState(null);

  useEffect(() => {
    getContacts();
    // Fetch messages initially
    getMessages(selectedId, TicketM);

    const intervalId = setInterval(() => {
      getMessages(selectedId, TicketM);
    }, 1000);

    // Cleanup interval on component unmount
    return () => {
      console.log('Cleaning up interval for fetching messages');
      clearInterval(intervalId);
    };
  }, [selectedId, TicketM]); // eslint-disable-line

  const handleSubmit = async (e) => {
    e.preventDefault();
    const messageInput = document.getElementById('message');
    const messageValue = messageInput.value.trim();

    if (messageValue !== '') {
      const formData = new FormData();
      formData.append('message', messageValue);
      formData.append('ticketNo', TicketM);
      formData.append('file', file);
      formData.append('recieverId', selectedId);
      formData.append('senderId', Cookies.get('adminId'));
      formData.append('name', Cookies.get('adminName'));

      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/Dealer/chat`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'api-key': process.env.REACT_APP_API_KEY,
          },
        });

        const { data } = response;
        if (data.message === 'added') {
          messageInput.value = '';
          setFile(null);
        }
      } catch (error) {
        console.error('Error:', error.message);
      }
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Sidebar />
      <div className='main-content'>

          <div className="conversation" style={{ marginLeft: '8px' }}>
            <h3 style={{ color: 'white', fontWeight: 'bold' }}>
              Your Conversation (Request #
              {TicketM}
              )
            </h3>
            <center>
              <MDBRow className="justify-content-center">
                <MDBCol md={8}>
                        <div
                          ref={messagesContainerRef}
                          style={{
                            backgroundColor: 'rgb(45, 53, 60)', padding: '10px', color: 'white', borderRadius: '7px', height: '400px', overflowY: 'auto',
                          }}
                        >
                {messages.map((message, index) => (
                  <div
                    key={index} className={`${message.senderId === 1 ? 'ME' : message.firstname === 'undefined' ? 'sender-user' : 'sender-admin'}`}
                    style={{
                      borderRadius: '.375rem', marginBottom: '20px',
                    }}
                  >
                    <p style={{ fontWeight: 'bold', marginBottom: '0' }}>{message.senderId === 1 ? 'ME' : message.firstname === 'undefined' ? 'Customer' : message.firstname}</p>
                    <p style={{ marginTop: '0', marginBottom: '0' }}>{message.message}</p>
                    {message.file && (
                      <div
                        style={{
                          display: 'flex', alignItems: 'center', marginTop: '0', marginBottom: '0',
                        }}
                      >
                        <MDBIcon fas icon="file" />
                        <p style={{ marginLeft: '5px', marginTop: '0', marginBottom: '0' }}><a href={`${process.env.REACT_APP_BACKEND_URL}/files/${message.file}`}>{message.file}</a></p>
                      </div>
                    )}
              </div>
                ))}
                  </div>

              <form onSubmit={handleSubmit}>
                <MDBInput
                  placeholder="Your Message" id="message"
                  type="text" style={{ backgroundColor: '#303030', marginTop: '7px', color: '#9ca3af' }}
                />
                <MDBInput
                  id="filen" type="file"
                  style={{ backgroundColor: 'rgb(48, 48, 48)', marginTop: '7px', color: '#fff' }} onChange={(event) => setFile(event.target.files[0])}
                />
                <MDBRow style={{ marginTop: '10px' }}>
                  <MDBCol md={12}>
                    <MDBBtn style={{ backgroundColor: '#35cce6', width: '100%', marginTop: '5px' }} type="submit">Send Message</MDBBtn>
                  </MDBCol>
                </MDBRow>
              </form>

                    </MDBCol>
              </MDBRow>
            </center>

      </div>
    </div>
    </div>
  );
};

export default Chat;
