import React, { useState, useEffect } from 'react';
import {
  MDBTable, MDBTableHead, MDBTableBody,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
} from 'mdb-react-ui-kit';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../../parts/sidebar.jsx';

const UserDetails = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [history, setHistory] = useState([]);
  const { id } = useParams();

  const getSubscriptions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/Admin/getSubscriptions`, {
        headers: {
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data;
      console.log(responseData);
      setSubscriptions(responseData.data.filter((item) => item.userId === id));
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const getHistory = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/Admin/gethistory`, {
        headers: {
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data;
      console.log(responseData);
      setHistory(responseData.data.filter((item) => item.userId === id));
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  useEffect(() => {
    getSubscriptions();
    getHistory();
  }, []); // eslint-disable-line

  function isValidDate(date) {
    return !Number.isNaN(date.getTime());
  }

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Sidebar />
      <div className='main-content'>
      <MDBRow style={{ margin: '30px' }}>
      <MDBCol sm='6'>
        {subscriptions.map((item, index) => (
            <MDBCard key={index} style={{ marginTop: '20px' }}>
            <MDBCardBody>
              <MDBCardTitle>Subscription</MDBCardTitle>
              <MDBCardText>
              <p>
                Starting Date: {
                  isValidDate(new Date(item.dated))
                    ? new Date(item.dated).toLocaleString()
                    : 'No Subscription'
                }
              </p>
              <p>
                Ending Date: {
                  isValidDate(new Date(item.dated))
                    ? new Date(new Date(item.dated).setFullYear(new Date(item.dated).getFullYear() + 1)).toLocaleString()
                    : 'No Subscription'
                }
              </p>
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        ))}
      </MDBCol>
      <MDBCol sm='6'>
        <MDBCard>
          <MDBCardBody>
            <MDBCardTitle>User History</MDBCardTitle>
            <MDBCardText>
            <MDBTable align='middle'>
          <MDBTableHead>
            <tr>
              <th scope='col'>Car Make</th>
              <th scope='col'>Controller</th>
              <th scope='col'>Solution</th>
              <th scope='col'>Dated</th>
            </tr>
          </MDBTableHead>
            <MDBTableBody>
               {history.map((item, index) => (
                <tr key={index}>
                  <td>
                      <p className='fw-normal mb-1'>{item.make}</p>
                  </td>
                  <td>
                      <p className='fw-normal mb-1'>{item.controller}</p>
                  </td>
                  <td>
                      <p className='fw-normal mb-1'><a href={`${process.env.REACT_APP_BACKEND_URL}/solutions/${item.solution}`}>Solution</a></p>
                  </td>
                  <td>
                      <p className='text-muted mb-0'>{new Date(item.dated).toLocaleString()}</p>
                  </td>
                </tr>
               ))}
            </MDBTableBody>
            </MDBTable>
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
      </div>
    </div>
  );
};

export default UserDetails;
