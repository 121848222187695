import React, { useState, useEffect } from 'react';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import axios from 'axios';
import Sidebar from '../../parts/sidebar.jsx';

const Subscriptions = () => {
  const [subscriptions, setSubscriptions] = useState([]);

  const getSubscriptions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/Admin/getSubscriptions`, {
        headers: {
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data;
      setSubscriptions(responseData.data);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  useEffect(() => {
    getSubscriptions();
  }, []);

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Sidebar />
      <div className='main-content'>
        <h3 style={{ color: 'black', marginLeft: '2%', fontFamily: 'bahnschrift' }}>Subscriptions</h3>
        <MDBTable align='middle'>
          <MDBTableHead>
            <tr>
              <th scope='col'>User Name</th>
              <th scope='col'>Email</th>
              <th scope='col'>Subscription</th>
              <th scope='col'>Start Date</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {subscriptions.map((item, index) => (
              <tr key={index}>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='ms-3'>
                      <p className='fw-bold mb-1'>{item.firstname}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <p className='fw-normal mb-1'>{item.email}</p>
                </td>
                <td>
                  <p className='text-muted mb-0'>Annual</p>
                </td>
                <td>
                  <p className='text-muted mb-0'>{new Date(item.dated).toLocaleString()}</p>
                </td>
              </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
      </div>
    </div>
  );
};

export default Subscriptions;
