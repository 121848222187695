import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateAdmin = ({ component: Component, ...rest }) => {
  const [check, setCheck] = useState(true);
  const [valid, setValid] = useState();

  useEffect(() => {
    async function checker() {
      try {
        setCheck(false);
        setValid(true);
      } catch (error) {
        console.error('Error:', error);
      }
    }

    checker();
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (check ? null : valid ? <Component {...props} /> : <Redirect to="/404" />) // eslint-disable-line
      }
    />
  );
};

export default PrivateAdmin;
