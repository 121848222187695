import React, { useState, useEffect } from 'react';
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBSpinner,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
  MDBInput,
  MDBBtn,
} from 'mdb-react-ui-kit';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Sidebar from '../../parts/sidebar.jsx';

const Requests = () => {
  const makeImages = {
    'Alfa Romeo-Fiat': '/Assets/carbrands/alfafiat.png',
    Alpine: '/Assets/carbrands/alpine.png',
    'Aston Martin': '/Assets/carbrands/astonmartin.png',
    Bentley: '/Assets/carbrands/bentley.png',
    'Bmw-Mini': '/Assets/carbrands/bmwmini.png',
    Cadillac: '/Assets/carbrands/cadillac.png',
    Challenger: '/Assets/carbrands/challenger.png',
    Chevrolet: '/Assets/carbrands/chevrolet.png',
    Chrysler: '/Assets/carbrands/chrysler.png',
    Dacia: '/Assets/carbrands/dacia.png',
    Daewoo: '/Assets/carbrands/daewoo.png',
    Dallara: '/Assets/carbrands/dallara.png',
    Dodge: '/Assets/carbrands/dodge.png',
    Ferrari: '/Assets/carbrands/ferrari.png',
    Furt: '/Assets/carbrands/ford.png',
    Ford: '/Assets/carbrands/ford.png',
    Geely: '/Assets/carbrands/geely.png',
    Genesis: '/Assets/carbrands/genesis.png',
    GMC: '/Assets/carbrands/gmc.png',
    GWM: '/Assets/carbrands/gwm.png',
    Holden: '/Assets/carbrands/holden.png',
    Honda: '/Assets/carbrands/honda.png',
    Hummer: '/Assets/carbrands/hummer.png',
    Hyundai: '/Assets/carbrands/hyundai.png',
    Infiniti: '/Assets/carbrands/infiniti.png',
    Isuzu: '/Assets/carbrands/isuzu.png',
    Iveco: '/Assets/carbrands/iveco.png',
    Jac: '/Assets/carbrands/jac.png',
    Jaguar: '/Assets/carbrands/jaguar.png',
    Jeep: '/Assets/carbrands/jeep.png',
    Kia: '/Assets/carbrands/kia.png',
    Lamborghini: '/Assets/carbrands/lamborghini.png',
    Lancia: '/Assets/carbrands/lancia.png',
    'Land Rover': '/Assets/carbrands/landrover.png',
    Lexus: '/Assets/carbrands/lexus.png',
    Lincoln: '/Assets/carbrands/lincoln.png',
    Lotus: '/Assets/carbrands/lotus.png',
    Luxgen: '/Assets/carbrands/luxgen.png',
    'Lynk & Co': '/Assets/carbrands/lynk.png',
    Mahindra: '/Assets/carbrands/mahindra.png',
    Maserati: '/Assets/carbrands/maserati.png',
    Mazda: '/Assets/carbrands/mazda.png',
    McLaren: '/Assets/carbrands/mclaren.png',
    'Mercedes-Benz': '/Assets/carbrands/mercedes.png',
    Mercury: '/Assets/carbrands/mercury.png',
    MG: '/Assets/carbrands/mg.png',
    Mitsubishi: '/Assets/carbrands/mitsubishi.png',
    Nissan: '/Assets/carbrands/nissan.png',
    Pontiac: '/Assets/carbrands/pontiac.png',
    'Psa-Opel': '/Assets/carbrands/psa.png',
    'PSA-Opel': '/Assets/carbrands/psa.png',
    Renault: '/Assets/carbrands/renault.png',
    Roewe: '/Assets/carbrands/roewe.png',
    'Rolls Royce': '/Assets/carbrands/rollsroyce.png',
    Rover: '/Assets/carbrands/rover.png',
    Saab: '/Assets/carbrands/saab.png',
    Saturn: '/Assets/carbrands/saturn.png',
    Smart: '/Assets/carbrands/smart.png',
    SsangYong: '/Assets/carbrands/ssangyong.png',
    Subaru: '/Assets/carbrands/subaru.png',
    Suzuki: '/Assets/carbrands/suzuki.png',
    Toyota: '/Assets/carbrands/toyota.png',
    VAG: '/Assets/carbrands/vag.png',
    Volvo: '/Assets/carbrands/volvo.png',
    WEY: '/Assets/carbrands/wey.png',
  };

  const renderMakeImage = (make) => {
    const imagePath = makeImages[make];
    if (imagePath) {
      return (
        <img
          src={imagePath} alt={make}
          title={make} style={{ width: '100%', maxWidth: '150px', height: 'auto' }}
        />
      );
    }
    return make;
  };

  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);
  const [submit, setSubmit] = useState(false);
  const [reqId, setReqId] = useState('');
  const [requests, setRequests] = useState([]);
  const [backup, setBackup] = useState([]);

  const [reqtype, setReqtype] = useState('');

  const toggleModal = () => setShowModal(!showModal);

  async function getRequests() {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/Admin/getRequests`);

      const data = await response.json();
      const reversedArray = [...data.data].reverse();
      setRequests(reversedArray);
      setBackup(reversedArray);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    document.body.style.overflowX = 'hidden';
    getRequests();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);
    const form = e.target;
    const formData = new FormData();
    formData.append('reqId', reqId);
    formData.append('file', file);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/Admin/requestSolution`, formData, {});
      const { data } = response;
      if (data.message === 'added') {
        form.reset();
        setSubmit(false);
        setShowModal(false);
        getRequests();
      }
    } catch (error) {
      console.error('Error:', error.message);
      setSubmit(false);
    }
  };

  const handleProcessing = async (id) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/Admin/Processing?reqId=${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_API_KEY,
        },
      });
      const { data } = response;
      if (data.message === 'added') {
        getRequests();
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you are sure to delete?')) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/Dealer/deleteTicket?reqId=${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'api-key': process.env.REACT_APP_API_KEY,
          },
        });
        const { data } = response;
        if (data.message === 'deleted') {
          getRequests();
        }
      } catch (error) {
        console.error('Error:', error.message);
      }
    }
  };

  const [sortDirection, setSortDirection] = useState('asc');

  const sortByRequestId = () => {
    setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));

    setRequests((prevRequests) => [
      ...prevRequests.sort((a, b) => {
        const sortFactor = sortDirection === 'asc' ? 1 : -1;
        return sortFactor * (a.Id - b.Id);
      }),
    ]);
  };

  const handleClose = async (id) => {
    if (window.confirm('Are you sure you want to close?')) {
      const formData = new FormData();
      formData.append('reqId', id);

      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/Admin/CloseRequest`, formData, {
          headers: {
            'Content-Type': 'application/json',
            'api-key': process.env.REACT_APP_API_KEY,
          },
        });
        const { data } = response;
        if (data.message === 'added') {
          getRequests();
        }
      } catch (error) {
        console.error('Error:', error.message);
      }
    }
  };

  const getTypeClasses = (type) => {
    const baseClasses = 'rounded-full text-[10px]';
    switch (type) {
      case 'Premium':
        return `${baseClasses} bg-premiumuser`;
      default:
        return `${baseClasses} bg-normaluser`;
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Sidebar />
      <div className="main-content">
        <h3 style={{ color: 'black', marginLeft: '2%', fontFamily: 'bahnschrift' }}>Requests</h3>

        <MDBRow style={{ margin: '20px' }}>
          <MDBCol md={12}>
            <MDBCard className="label-left" style={{ margin: '30px', backgroundColor: '#2d353c', height: '100%' }}>
              <MDBCardBody>
                <MDBCardText>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={reqtype}
                    onChange={(e) => {
                      setReqtype(e.target.value);
                      if (e.target.value === 'All') {
                        setRequests(backup);
                      } else {
                        setRequests(backup.filter((item) => item.status === e.target.value));
                      }
                    }}
                    style={{ width: '400px', marginBottom: '50px' }}
                  >
                    <option selected>Filter Requests</option>
                    <option value="Open">Open</option>
                    <option value="Closed">Closed</option>
                    <option value="All">All</option>
                  </select>
                  <div style={{ overflowX: 'auto' }}>
                    <MDBTable align="middle" className="table-responsive requests_table">
                      <MDBTableHead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">Date</th>
                          <th
                            scope="col" style={{ cursor: 'pointer' }}
                            onClick={sortByRequestId}
                          >
                            Req# {sortDirection === 'asc' ? '▲' : '▼'}
                          </th>
                          <th scope="col">User</th>
                          <th scope="col">Make</th>
                          <th scope="col">Controller</th>
                          <th scope="col">Solution</th>
                          <th scope="col">Comments</th>
                          {/* <th scope='col'>Dated</th> */}
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        {requests.map((item, index) => (
                          <tr key={index}>
                            <td data-label="Status">
                              {item.status === 'Closed' ? (
                                <i
                                  className="fa fa-check-circle" aria-hidden="true"
                                  style={{ color: 'green' }}
                                ></i>
                              ) : item.status === 'Open' ? (
                                <i
                                  className="fa fa-circle" aria-hidden="true"
                                  style={{ color: 'red' }}
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-dot-circle" aria-hidden="true"
                                  style={{ color: 'yellow' }}
                                ></i>
                              )}
                            </td>
                            <td data-label="Date">
                              {`${new Date(item.dated).toLocaleDateString()} ${new Date(
                                item.dated,
                              ).toLocaleTimeString()}`}
                            </td>
                            <td data-label="Request">{item.Id}</td>
                            <td data-label="User">
                              {`${item.firstname} ${item.lastname}`} <br />
                              <span className={getTypeClasses(item.type)}>{item.type}</span> <br />
                              <span className="text-xs">Credits: {item.credits}</span>
                            </td>
                            <td data-label="Make">
                              <div className="d-flex align-items-center">
                                <div className="image-container" title={item.make}>
                                  {renderMakeImage(item.make)}
                                  <span className="image-title">{item.make}</span>
                                </div>
                              </div>
                            </td>
                            <td data-label="Controller">{item.ecu}</td>
                            <td data-label="Solution">
                              {item.solutionType.split(',').map((solution, idx) => (
                                <div
                                  key={idx}
                                  style={{
                                    backgroundColor: '#c62840',
                                    color: '#f3f3f3',
                                    marginBottom: '4px',
                                    padding: '2px',
                                    textAlign: 'center',
                                    borderRadius: '25px',
                                    fontSize: '0.7em',
                                    fontWeight: 'bold',
                                    textShadow: '1px 1px 1px #000',
                                  }}
                                >
                                  {solution}
                                </div>
                              ))}
                            </td>
                            <td data-label="Comments">
                              <div className="commentHover" style={{ position: 'relative', display: 'inline-block' }}>
                                {item.comments && item.comments.length > 0 && (
                                  <>
                                    <i
                                      className="fa fa-exclamation-circle"
                                      aria-hidden="true"
                                      style={{ cursor: 'pointer' }}
                                    ></i>
                                    <div className="commentBox">{item.comments}</div>
                                  </>
                                )}
                              </div>
                            </td>
                            <td></td>
                            <td>
                              {item.status === 'Closed' && item.solution === 'Not Possible' ? (
                                ''
                              ) : item.status === 'Closed' ? (
                                <a
                                  href={`${process.env.REACT_APP_BACKEND_URL}/files/${item.solution}`}
                                  title="Download solution file"
                                >
                                  <i className="fa fa-cloud-upload" aria-hidden="true"></i>
                                </a>
                              ) : (
                                <i
                                  className="fa fa-plus"
                                  title="Add solution"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    toggleModal();
                                    setReqId(item.Id);
                                  }}
                                ></i>
                              )}
                            </td>
                            <td></td>
                            <td data-label="Functions">
                              <div className="request_buttons">
                                <div>
                                  {item.file === 'Not Possible' ? (
                                    'No Solution'
                                  ) : item.status === 'Closed' ? (
                                    ''
                                  ) : (
                                    <p
                                      className="text-muted mb-0"
                                      onClick={() => {
                                        handleProcessing(item.Id);
                                      }}
                                    >
                                      <a
                                        href={`${process.env.REACT_APP_BACKEND_URL}/files/${item.file}`}
                                        title="Download customers file"
                                      >
                                        <i className="fa fa-cloud-download" aria-hidden="true"></i>
                                      </a>
                                    </p>
                                  )}
                                </div>
                                <div>
                                  {item.status === 'Closed' ? (
                                    ''
                                  ) : item.status === 'Open' ? (
                                    <i
                                      className="fa fa-window-close request_close"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        handleClose(item.Id);
                                      }}
                                      title="Close"
                                    ></i>
                                  ) : (
                                    <i
                                      className="fa fa-window-close request_close"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        handleClose(item.Id);
                                      }}
                                      title="Close"
                                    ></i>
                                  )}
                                </div>
                                <div>
                                  <Link
                                    to={`/chat?id=${item.dealerId}&name=${item.firstname}&ticketno=${item.Id}`}
                                    title="Open Chat"
                                  >
                                    <i className="fas fa-comment"></i>
                                  </Link>
                                </div>
                                <div>
                                  <i
                                    className="fa fa-trash"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      handleDelete(item.Id);
                                    }}
                                  ></i>
                                </div>
                              </div>
                            </td>
                            <td style={{ display: 'none' }}></td>
                          </tr>
                        ))}
                      </MDBTableBody>
                    </MDBTable>
                  </div>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </div>

      <MDBModal
        show={showModal} onHide={() => setShowModal(false)}
        tabIndex="-1"
      >
        <MDBModalDialog centered size="md">
          <MDBModalContent style={{ color: 'black' }}>
            <MDBModalBody>
              <center>
                <h3 style={{ color: 'black', fontFamily: 'bahnschrift', marginTop: '15px' }}>Solution File</h3>
              </center>

              <center>
                <div>
                  <p style={{ marginTop: '30px' }}>Add Solution File</p>
                  <form onSubmit={handleSubmit}>
                    <div className="optional-label">
                      <label>Optional</label>
                    </div>
                    <MDBInput
                      type="file" id="file"
                      name="file" onChange={(event) => setFile(event.target.files[0])}
                    />
                    <MDBBtn
                      style={{
                        marginTop: '8px',
                        width: '100%',
                        color: 'white',
                        backgroundColor: 'black',
                      }}
                    >
                      {submit ? <MDBSpinner></MDBSpinner> : <span>Submit</span>}
                    </MDBBtn>
                  </form>
                </div>
              </center>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
};

export default Requests;
