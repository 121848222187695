import React from 'react';

function UserSearch({ handelSearch }) {
  return (
    <div className="search">
      <input
        type="text"
        placeholder="Search by name, email or user group"
        onChange={handelSearch}
        className="rounded-lg p-2 w-full border-2 border-gray-300 focus:outline-none focus:border-red-700 transition duration-500 ease-in-out max-w-lg text-gray-700"
      />
    </div>
  );
}

export default UserSearch;
