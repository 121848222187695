import React, { useState } from 'react';
import {
  MDBRow, MDBCol, MDBInput, MDBBtn, MDBSpinner,
} from 'mdb-react-ui-kit';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';

const colStyle = {
  height: '100%',
  overflow: 'hidden',
  padding: '0',
  position: 'relative',
};

export default function Login() {
  const [submit, setSubmit] = useState(false);

  const showErrorMessage = () => {
    toast.error('Invalid Login', {
      position: 'top-center',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);
    const data = {
      email: document.getElementById('email').value,
      password: document.getElementById('password').value,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/Admin/login`, data, {
        headers: {
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data;
      if (responseData.message === 'success') {
        Cookies.set('email', responseData.email, { expires: 1 });
        Cookies.set('adtoken', responseData.token, { expires: 1 });
        Cookies.set('adminEmail', responseData.adminEmail, { expires: 1 });
        Cookies.set('adminId', responseData.adminId, { expires: 1 });
        Cookies.set('adminName', responseData.adminName, { expires: 1 });
        Cookies.set('avatar', responseData.avatar, { expires: 1 });
        window.location.href = '/dashboard';
      } else if (responseData.message === 'invalid') {
        console.log(responseData);
        showErrorMessage();
      }
      setSubmit(false);
    } catch (error) {
      console.error('Error:', error.message);
      setSubmit(false);
    }
  };

  return (
    <MDBRow
      style={{
        margin: 0, width: '100%', height: '100vh', overflow: 'hidden', backgroundColor: '#1A2229',
      }}
    >
      <ToastContainer />
      <MDBCol
        md='12' style={{
          ...colStyle, display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh',
        }}
      >
        <div style={{ marginLeft: '20px', padding: '20px', width: '400px' }}>
          <center>
            <img
              src="./Assets/logo.png" alt=""
              style={{ width: '300px' }}
            />
          </center>
          <h3
            style={{
              color: '#fff', fontFamily: 'verdana,sans-serif!important', fontSize: '1.5em', fontWeight: '700', marginRight: '2%', textAlign: 'center', textShadow: '2px 2px 3px #000', textTransform: 'uppercase', marginTop: '30px', marginBottom: '30px', fontStyle: 'italic',
            }}
          >Admin Login </h3>
          <form onSubmit={handleSubmit}>
            <div>
              <MDBInput
                label='Email Address' id='email'
                name='email' type='text'
                style={{ backgroundColor: '#6c757d' }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <MDBInput
                label='Password' id='password'
                name='password' type='password'
                style={{ backgroundColor: '#6c757d' }}
              />
            </div>
            <MDBBtn
              style={{
                marginTop: '30px', width: '100%', backgroundColor: '#c32941', color: 'white',
              }}
            >
              {submit ? (
                <MDBSpinner></MDBSpinner>
              ) : (
                <span>Login</span>
              )}
            </MDBBtn>
          </form>

          <div
            style={{
              position: 'absolute', bottom: '20px', left: '0', right: '0', textAlign: 'center',
            }}
          >
            <hr style={{ color: '#adb5b3' }} />
            <p style={{ color: '#adb5b3', fontSize: '15px' }}>© Eculab All Right Reserved {new Date().getFullYear()}</p>
          </div>
        </div>
      </MDBCol>
    </MDBRow>
  );
}
