/* eslint-disable no-restricted-syntax */
import React, { useState, useRef } from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBRow,
  MDBCol,
  MDBSpinner,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBCheckbox,
} from 'mdb-react-ui-kit';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import Sidebar from '../../parts/sidebar.jsx';
import { useAddCarMutation, useGetCarsQuery, useGetControllersQuery } from '../../../redux/services/ecu.service';

const ECU = () => {
  /** REDUX QUERY=================RTK QUERY */
  const { data: cars, isLoading: isCarLoading } = useGetCarsQuery();
  const { data: backcontroller, isLoading: isBackControllerLoading } = useGetControllersQuery();

  const [addCarHandler/* , { isLoading: addCarLoading } */] = useAddCarMutation();

  const [submit, setSubmit] = useState(false);
  const [ori, setOri] = useState(null);
  const [modFiles, setModFiles] = useState([]);
  // const [cars, setCars] = useState([]);
  // const [backcontroller, setBackController] = useState([]);
  const [controllers, setControllers] = useState([]);
  const [carsubmit/* , setCarsubmit */] = useState(false);
  const [controllersubmit, setControllerSubmit] = useState(false);
  const [ecuController, setEcucontroller] = useState([]);
  const [settingsLock, setSettingsLock] = useState(false);

  const [fileSizeError, setFileSizeError] = useState('');

  const [selectCar, setSelectedCar] = useState('');
  const fileInputRef = useRef(null);
  const fileInputRefMod = useRef(null);

  // Add Makes
  const [newMake, setNewMake] = useState('');

  // Add Controller
  const [contrMake, setContMake] = useState('');
  const [newControllerAdd, setNewControllerAdd] = useState('');

  if (controllers) {
    console.log('Init');
  }

  function replaceSpecialCharacters(fileName) {
    // Regular expression to match any special character except underscore (_),(.) and hyphen (-)
    const regex = /[^a-zA-Z0-9. ]/g;
    const str = fileName.replace(regex, '-');
    return str.replace(/--/g, '-');
  }

  const throwFileSizeError = () => {
    setFileSizeError('The original file and mod files size must be same');
    toast.error('The original file and mod file size must be same', {
      position: 'top-center',
    });
  };

  const onFileInputChange = (files, field) => {
    setFileSizeError('');
    if (field === 'ori') {
      for (const file of modFiles) {
        if (file.size !== file[0].size) {
          throwFileSizeError();
          return;
        }
      }
      setOri(files[0]);
      return;
    }
    if (field === 'mod') {
      for (const file of files) {
        if (ori && ori.size !== file.size) {
          throwFileSizeError();
          return;
        }
      }
      setModFiles(files);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);
    const form = e.target;
    const formData = new FormData();
    const controller = document.getElementById('controller').value;
    formData.append('car', selectCar);
    formData.append('controller', controller);
    formData.append('ori', ori, replaceSpecialCharacters(ori.name));
    if (modFiles.length) {
      for (const file of modFiles) {
        formData.append('modFiles', file, replaceSpecialCharacters(file.name));
      }
    }
    formData.append('credits', document.getElementById('credits').value);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/Admin/createScripts?controller=${controller}&car=${selectCar}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'api-key': process.env.REACT_APP_API_KEY,
          },
        },
      );
      const { data } = response;
      if (data.message === 'added') {
        if (!settingsLock) {
          form.reset();
        } else {
          fileInputRefMod.current.value = null;
        }
        setSubmit(false);
      }
    } catch (error) {
      console.error('Error:', error.message);
      setSubmit(false);
    }
  };

  async function handleCars() {
    if (newMake === '') {
      alert('Enter Car Name');
    } else {
      const newmakedata = {
        car: newMake,
      };

      try {
        await addCarHandler({ data: newmakedata });
      } catch (error) {
        console.error('Error:', error);
      }
    }
  }

  async function handleController() {
    if (newControllerAdd === '' || contrMake === '') {
      alert('Give complete details');
    } else {
      setControllerSubmit(true);
      const newcontrollerdata = {
        make: contrMake,
        controller: newControllerAdd,
      };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/Admin/addController`,
          newcontrollerdata,
          {},
        );
        const { data } = response;
        if (data.message === 'added') {
          // getDetails();
          setControllerSubmit(false);
        }
      } catch (error) {
        console.error('Error:', error.message);
        setControllerSubmit(false);
      }
    }
  }

  async function handleCarsDelete(id) {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/Admin/deletemake?id=${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_API_KEY,
        },
      });
      const { data } = response;
      if (data.message === 'deleted') {
        // getDetails();
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  }

  async function handleControllerDelete(id) {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/Admin/deleteController?id=${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_API_KEY,
        },
      });
      const { data } = response;
      if (data.message === 'deleted') {
        window.location.href = '/ecu';
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  }

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <ToastContainer />
      <Sidebar />

      <div className="main-content">
        <h3 style={{ color: 'white', marginLeft: '2%' }}>Choose Your Solution </h3>
        <MDBRow style={{ margin: '20px' }}>
          <MDBCol md={4}>
            <MDBCard
              className="text-center label-left"
              style={{
                backgroundColor: '#2d353c',
                height: '100%',
              }}
            >
              <MDBCardBody>
                <h4>Add Solution</h4>
                <form onSubmit={handleSubmit}>
                  <div className="container" style={{ marginBottom: '20px' }}>
                    <select
                      className="form-select"
                      id="car"
                      name="solution"
                      onChange={(e) => {
                        const selectedCarId = +e.target.value;
                        const selectedText = e.target.options[e.target.selectedIndex].text;
                        setSelectedCar(selectedText);
                        setEcucontroller(backcontroller.filter((item) => item.makeId === selectedCarId));
                      }}
                    >
                      <option value="">Select Car</option>
                      {cars?.map((item, index) => (
                        <option key={index} value={item.Id}>
                          {item.carname}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="container" style={{ marginBottom: '10px' }}>
                    <select
                      className="form-select" id="controller"
                      name="controller"
                    >
                      <option value="">Select Controller</option>
                      {ecuController.map((item, index) => (
                        <option value={item.controllername} key={index}>
                          {item.controllername}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div
                    style={{
                      marginTop: '7px',
                      marginLeft: '11px',
                      display: 'none',
                    }}
                  >
                    <label htmlFor="">Credits</label>
                    <MDBInput
                      label="Credits" id="credits"
                      type="number" defaultValue={0}
                    />
                  </div>
                  <div
                    style={{
                      marginTop: '15px',
                      marginBottom: '15px',
                      marginLeft: '11px',
                      marginRight: '11px',
                    }}
                  >
                    <label style={{ display: 'flex' }} htmlFor="">
                      Original File
                    </label>
                    <MDBInput
                      id="ori"
                      name="ori"
                      type="file"
                      accept=".bin"
                      onChange={(event) => onFileInputChange(event.target.files, 'ori')}
                      ref={fileInputRef}
                      style={{ color: '#fff' }}
                    />
                  </div>
                  <div
                    style={{
                      marginTop: '15px',
                      marginLeft: '11px',
                      marginRight: '11px',
                    }}
                  >
                    <label style={{ display: 'flex' }} htmlFor="">
                      Mod File
                    </label>
                    <MDBInput
                      id="mod"
                      name="mod"
                      type="file"
                      multiple
                      // accept only bin file
                      accept=".bin"
                      onChange={(event) => onFileInputChange(event.target.files, 'mod')}
                      ref={fileInputRefMod}
                      style={{ color: '#fff' }}
                    >
                      {fileSizeError && (
                        <div
                          id="helperTextExample" className="form-helper text-start"
                          style={{ color: 'red' }}
                        >
                          {fileSizeError}
                        </div>
                      )}
                    </MDBInput>
                  </div>
                  <div
                    style={{
                      marginLeft: '12px',
                      marginTop: '20px',
                      display: 'flex',
                      justifyContent: 'space-around',
                      marginRight: '11px',
                    }}
                  >
                    <MDBCheckbox
                      name="flexCheck"
                      id="flexCheckChecked"
                      label="Lock Settings"
                      value={settingsLock}
                      onChange={() => {
                        setSettingsLock(!settingsLock);
                      }}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-mdb-ripple-init
                    style={{
                      margin: '15px',
                      color: 'white',
                      backgroundColor: 'black',
                      width: '-webkit-fill-available',
                    }}
                  >
                    {submit ? <MDBSpinner></MDBSpinner> : <span>Add Solution</span>}
                  </button>
                </form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md={4}>
            <MDBCard
              className="text-center label-left"
              style={{
                backgroundColor: '#2d353c',
                height: '100%',
              }}
            >
              <MDBCardBody>
                <h4>Add Car</h4>
                <div style={{ marginTop: '0' }}>
                  <MDBInput
                    placeholder="Car Name"
                    id="newmake"
                    type="text"
                    value={newMake}
                    onChange={(e) => {
                      setNewMake(e.target.value);
                    }}
                    style={{ background: '#fff' }}
                  />
                </div>
                <button
                  onClick={handleCars}
                  className="btn btn-primary"
                  data-mdb-ripple-init
                  style={{
                    marginTop: '20px',
                    color: 'white',
                    backgroundColor: 'black',
                    width: '100%',
                  }}
                >
                  {carsubmit ? <MDBSpinner></MDBSpinner> : <span>Add Car</span>}
                </button>
                <div
                  style={{
                    height: '300px',
                    overflowY: 'auto',
                    overflowX: 'auto',
                    marginTop: '30px',
                  }}
                >
                  <MDBTable style={{ marginTop: '50x', color: '#fff' }} className="addcar_table">
                    <MDBTableHead>
                      <tr>
                        <th scope="col" style={{ textAlign: 'left' }}>
                          Make
                        </th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {isCarLoading ? (
                        <MDBSpinner className="text-center mt-4" size="lg"></MDBSpinner>
                      ) : (
                        cars?.map((item, index) => (
                          <tr key={index}>
                            <td
                              style={{ cursor: 'pointer', textAlign: 'left' }}
                              onClick={() => {
                                setControllers(backcontroller.filter((controller) => controller.makeId === item.Id));
                              }}
                            >
                              {item.carname}
                            </td>
                            <td>
                              <i className="fa fa-edit"></i>
                            </td>
                            <td>
                              <i
                                className="fa fa-trash"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  if (window.confirm('Are you sure you want to delete?')) {
                                    handleCarsDelete(item.Id);
                                  }
                                }}
                              ></i>
                            </td>
                          </tr>
                        ))
                      )}
                    </MDBTableBody>
                  </MDBTable>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md={4}>
            <MDBCard
              className="text-center label-left"
              style={{
                backgroundColor: '#2d353c',
                height: '100%',
              }}
            >
              <MDBCardBody>
                <h4>Add Controller</h4>
                <select
                  className="form-select"
                  id="car"
                  name="solution"
                  value={contrMake}
                  onChange={(e) => {
                    setContMake(e.target.value);
                  }}
                  style={{ marginTop: '0', background: '#fff' }}
                >
                  <option value="">Select Car</option>
                  {cars?.map((item, index) => (
                    <option value={item.carname} key={index}>
                      {item.carname}
                    </option>
                  ))}
                </select>
                <div style={{ marginTop: '20px' }}>
                  <MDBInput
                    placeholder="Controller"
                    id="newcontroller"
                    type="text"
                    style={{ background: '#fff' }}
                    value={newControllerAdd}
                    onChange={(e) => {
                      setNewControllerAdd(e.target.value);
                    }}
                  />
                </div>
                <button
                  onClick={handleController}
                  className="btn btn-primary"
                  data-mdb-ripple-init
                  style={{
                    marginTop: '20px',
                    color: 'white',
                    backgroundColor: 'black',
                    width: '100%',
                  }}
                >
                  {controllersubmit ? <MDBSpinner></MDBSpinner> : <span>Add controller</span>}
                </button>
                <div
                  style={{
                    height: '300px',
                    overflowY: 'auto',
                    overflowX: 'auto',
                    maxWidth: '100%',
                    marginTop: '30px',
                  }}
                >
                  <MDBTable style={{ marginTop: '50x', color: '#fff' }} className="addcontroler_table">
                    <MDBTableHead>
                      <tr>
                        <th
                          scope="col"
                          style={{
                            textAlign: 'left',
                          }}
                          colSpan={3}
                        >
                          Controller
                        </th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody style={{ textAlign: 'center' }}>
                      {isBackControllerLoading ? (
                        <MDBSpinner className="text-center mt-4 " size="lg"></MDBSpinner>
                      ) : (
                        backcontroller?.map((item, index) => (
                          <tr key={index}>
                            <td style={{ textAlign: 'left' }}>{item.carname}</td>
                            <td style={{ textAlign: 'left' }}>{item.controllername}</td>
                            <td>
                              <i className="fa fa-edit"></i>
                              <i
                                className="fa fa-trash"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  if (window.confirm('Are you sure you want to delete?')) {
                                    handleControllerDelete(item.Id);
                                  }
                                }}
                              ></i>
                            </td>
                          </tr>
                        ))
                      )}
                    </MDBTableBody>
                  </MDBTable>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </div>
    </div>
  );
};

export default ECU;
