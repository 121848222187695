import React, { useState, useEffect } from 'react';
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBSpinner,
  MDBCard,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
  MDBInput,
  MDBBtn,
} from 'mdb-react-ui-kit';
import axios from 'axios';
import moment from 'moment';
import Sidebar from '../../parts/sidebar.jsx';
import UserSearch from './UserSearch.jsx';

const Usersinfo = () => {
  const [dealers, setDealers] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [submit, setSubmit] = useState(false);

  const [fname, setFName] = useState('');
  const [lname, setLName] = useState('');
  const [email, setEmail] = useState('');
  const [postCode, setPostCode] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [group, setGroup] = useState('');
  const [credits, setCredits] = useState();
  const [vatNumber, setVatNumber] = useState('');
  const [isVatFree, setIsVatFree] = useState(false);
  const [date, setDate] = useState('');
  const [id, setId] = useState('');

  const [showUserSolutionsModal, setShowUserSolutionsModal] = useState(false);

  const getDealers = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/Admin/getDealers`,
        {},
      );
      const responseData = response.data;
      setDealers(responseData.data);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };
  console.log(dealers);
  useEffect(() => {
    axios
      .get('https://restcountries.com/v3.1/all')
      .then((response) => {
        const countryList = response.data.map((country) => ({
          name: country.name.common,
          code: country.cca2,
        }));
        setCountries(countryList);
      })
      .catch((error) => {
        console.error('Error fetching countries:', error);
      });
    getDealers();
  }, []);

  const deleteDealers = async (dealerId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/Admin/deletedealer?id=${dealerId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'api-key': process.env.REACT_APP_API_KEY,
          },
        },
      );
      const responseData = response.data;
      if (responseData.message === 'deleted') {
        getDealers();
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };
  console.log(isVatFree);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);
    const form = e.target;
    const formData = new FormData();
    formData.append('firstname', fname);
    formData.append('lastname', lname);
    formData.append('email', email);
    formData.append('location', selectedCountry);
    formData.append('postcode', postCode);
    formData.append('city', city);
    formData.append('address', address);
    formData.append('phone', phone);
    formData.append('credits', credits);
    formData.append('type', group);
    formData.append('subscribeDate', moment(date).format('YYYY-MM-DD'));
    formData.append('id', id);
    formData.append('vatNumber', vatNumber);
    formData.append('isVatFree', isVatFree);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/Admin/updateUser`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            'api-key': process.env.REACT_APP_API_KEY,
          },
        },
      );
      const { data } = response;
      if (data.message === 'updated') {
        form.reset();
        setShowModal(false);
        getDealers();
        setSubmit(false);
      }
    } catch (error) {
      console.error('Error:', error.message);
      setSubmit(false);
    }
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleSearch = async (e) => {
    const searchTerm = e.target.value.toLowerCase();
    if (!searchTerm) {
      getDealers(); // Fetch all dealers again if search term is empty
      return;
    }

    // Filter the dealers with search term
    const filteredDealers = dealers.filter(
      (dealer) => dealer.firstname.toLowerCase().includes(searchTerm)
        || dealer.lastname.toLowerCase().includes(searchTerm)
        || dealer.email.toLowerCase().includes(searchTerm)
        || dealer.type.toLowerCase().includes(searchTerm),
    );

    // Update the state with the filtered dealers
    setDealers(filteredDealers);
  };

  const [userHistory, setUserHistory] = useState(null);

  const fetchUserHistory = async (userId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/Admin/userHistory?id=${userId}`,
      );
      const responseData = response.data;
      setUserHistory(responseData.data);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const handleShowUserSolutionsModal = (userId) => {
    fetchUserHistory(userId);
    setShowUserSolutionsModal(true);
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Sidebar />
      <div className="main-content">
        <h3 style={{ color: 'black', marginLeft: '2%' }}>Users</h3>

        <MDBRow style={{ margin: '20px' }}>
          <MDBCol md={12}>
            <MDBCard
              className="label-left"
              style={{
                margin: '30px',
                backgroundColor: '#2d353c',
                height: '100%',
              }}
            >
              <MDBCardBody>
                <div>
                  <div style={{ overflowX: 'auto' }}>
                    <UserSearch handelSearch={handleSearch} />
                    <MDBTable align="middle" className="table-responsive users_table text-white">
                      <MDBTableHead>
                        <tr>
                          <th scope="col" style={{ width: '20%' }}>
                            Name
                          </th>
                          <th scope="col" style={{ width: '20%' }}>
                            Email
                          </th>
                          <th
                            scope="col"
                            style={{ width: '15%', textAlign: 'center' }}
                          >
                            Credits
                          </th>
                          <th scope="col" style={{ width: '15%' }}>
                            User Group
                          </th>
                          <th scope="col" style={{ width: '15%' }}>
                            Dated
                          </th>
                          <th
                            scope="col"
                            style={{ width: '5%', textAlign: 'center' }}
                          ></th>
                          <th
                            scope="col"
                            style={{ width: '5%', textAlign: 'center' }}
                          ></th>
                          <th
                            scope="col"
                            style={{ width: '5%', textAlign: 'center' }}
                          ></th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        {dealers.map((item, index) => (
                          <tr key={index}>
                            <td data-label="Name" style={{ width: '20%' }}>
                              {/* <Link to={`/user-details/${item.Id}`}><p className='fw-bold mb-1'> */}
                              {`${item.firstname} ${item.lastname}`}
                              {/* </p></Link> */}
                            </td>
                            <td data-label="E-Mail" style={{ width: '25%' }}>{item.email}</td>
                            <td data-label="Credits" style={{ width: '15%', textAlign: 'center' }}>
                              {item.credits}
                            </td>
                            <td data-label="User Group" style={{ width: '15%' }}>{item.type}</td>
                            <td data-label="Dated" style={{ width: '10%' }}>
                              {new Date(item.createdAt).toLocaleString()}
                            </td>
                            <td data-label="User Files" style={{ width: '5%', textAlign: 'center' }}>
                              <i
                                className="fa fa-list"
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleShowUserSolutionsModal(item.Id)
                                }
                              ></i>
                            </td>
                            <td data-label="Edit User" style={{ width: '5%', textAlign: 'center' }}>
                              <i
                                className="fa fa-edit"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  setFName(item.firstname);
                                  setLName(item.lastname);
                                  setEmail(item.email);
                                  setPostCode(item.postcode);
                                  setCity(item.city);
                                  setAddress(item.address);
                                  setPhone(item.phone);
                                  setCredits(item.credits);
                                  setVatNumber(item.vatNumber || '');
                                  setSelectedCountry(item.location);
                                  setGroup(item.type);
                                  setId(item.Id);
                                  setIsVatFree(Boolean(item.isVatFree));
                                  setDate(
                                    item.subscription_date !== null
                                      ? moment(item.subscription_date).format(
                                        'YYYY-MM-DD',
                                      )
                                      : '',
                                  );
                                  setShowModal(true);
                                }}
                              ></i>
                            </td>
                            <td data-label="Delete User" style={{ width: '5%', textAlign: 'center' }}>
                              <i
                                className="fa fa-trash"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      'Are you sure you want to delete?',
                                    )
                                  ) {
                                    deleteDealers(item.Id);
                                  }
                                }}
                              ></i>
                            </td>
                          </tr>
                        ))}
                      </MDBTableBody>
                    </MDBTable>
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </div>

      <MDBModal
        show={showUserSolutionsModal}
        onHide={() => setShowUserSolutionsModal(false)}
        tabIndex="-1"
      >
        <div className="max-w-2xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute">
          <MDBModalContent style={{ color: 'black' }}>
            <MDBModalBody>
              <div className="text-white">
                <h4 className="text-lg text-center">User Solutions</h4>

                <ul className="flex flex-col max-h-[700px] overflow-y-scroll m-0 px-4 space-y-4">
                  {userHistory
                    && userHistory.map((solution, index) => (
                      <li key={index} className="py-2 border-b border-gray-600">
                        {solution.solution} (
                        {new Date(solution.dated).toLocaleString()})
                      </li>
                    ))}
                </ul>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </div>
      </MDBModal>

      <MDBModal
        show={showModal}
        onHide={() => setShowModal(false)}
        tabIndex="-1"
      >
        <MDBModalDialog centered size="md">
          <MDBModalContent style={{ color: 'black' }}>
            <MDBModalBody>
              <center>
                <h3
                  style={{
                    color: 'black',
                    fontFamily: 'bahnschrift',
                    marginTop: '15px',
                  }}
                >
                  Update User
                </h3>
              </center>

              <center>
                <div>
                  <form onSubmit={handleSubmit}>
                    <MDBInput
                      placeholder="First Name"
                      id="fname"
                      type="text"
                      value={fname}
                      onChange={(e) => {
                        setFName(e.target.value);
                      }}
                    />
                    <MDBInput
                      placeholder="Last Name"
                      id="lname"
                      type="text"
                      style={{ marginTop: '5px' }}
                      value={lname}
                      onChange={(e) => {
                        setLName(e.target.value);
                      }}
                    />
                    <MDBInput
                      placeholder="Email"
                      id="email"
                      type="text"
                      style={{ marginTop: '5px' }}
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    <MDBInput
                      placeholder="Post Code"
                      id="postcode"
                      type="text"
                      style={{ marginTop: '5px' }}
                      value={postCode}
                      onChange={(e) => {
                        setPostCode(e.target.value);
                      }}
                    />
                    <MDBInput
                      placeholder="City"
                      id="city"
                      type="text"
                      style={{ marginTop: '5px' }}
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value);
                      }}
                    />
                    <MDBInput
                      placeholder="Address"
                      id="address"
                      type="text"
                      style={{ marginTop: '5px' }}
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                    />
                    <MDBInput
                      placeholder="Phone"
                      id="phone"
                      type="text"
                      style={{ marginTop: '5px' }}
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                    />
                    <MDBInput
                      placeholder="Credits"
                      id="credits"
                      type="number"
                      style={{ marginTop: '5px' }}
                      value={credits}
                      onChange={(e) => {
                        setCredits(e.target.value);
                      }}
                    />
                    <MDBInput
                      placeholder="VAT Number"
                      id="vatNumber"
                      type="text"
                      style={{ marginTop: '5px' }}
                      value={vatNumber}
                      onChange={(e) => {
                        setVatNumber(e.target.value);
                      }}
                    />
                    <MDBInput
                      placeholder="Subscription Date"
                      id="sd"
                      type="date"
                      style={{ marginTop: '5px' }}
                      value={date}
                      onChange={(e) => {
                        setDate(e.target.value);
                      }}
                    />
                    <select
                      className="form-control"
                      style={{ marginTop: '5px', color: 'black' }}
                      value={group}
                      onChange={(e) => {
                        setGroup(e.target.value);
                      }}
                      name="group"
                      required
                    >
                      <option value="">Select User Group</option>
                      <option value="Normal">Normal</option>
                      <option value="Premium">Premium</option>
                      <option value="Custom">Custom</option>
                    </select>
                    <select
                      className="form-control"
                      style={{ marginTop: '5px', color: 'black' }}
                      value={selectedCountry}
                      onChange={handleCountryChange}
                      name="country"
                      required
                    >
                      <option value="">Select Country</option>
                      {countries.map((country) => (
                        <option key={country.code} value={country.code}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                    <div className="checkbox-container">
                      <input
                        id="isVatFree"
                        type="checkbox"
                        checked={isVatFree}
                        onChange={() => setIsVatFree(!isVatFree)}
                        style={{ width: '15px', height: '15px' }}
                      />
                      <label
                        htmlFor="isVatFree"
                        className="form-check-label font-medium inline-block ml-2 white-label"
                      >
                        VAT free user
                      </label>
                    </div>
                    <MDBBtn
                      style={{
                        marginTop: '8px',
                        width: '100%',
                        backgroundColor: 'black',
                        color: 'white',
                      }}
                    >
                      {submit ? <MDBSpinner></MDBSpinner> : <span>Update</span>}
                    </MDBBtn>
                  </form>
                </div>
              </center>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
};

export default Usersinfo;
