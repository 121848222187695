import React, { useEffect, useState } from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBInput, MDBRow, MDBCol, MDBSpinner,
  MDBTable, MDBTableHead, MDBTableBody,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
  MDBBtn,
} from 'mdb-react-ui-kit';
import axios from 'axios';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Sidebar from '../../parts/sidebar.jsx';

const DraggableTableRow = ({
  id, index, moveRow, data, deleteSolutions,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [solutionname, setSolutionname] = useState('');
  const [category, setCategory] = useState('');
  const [Id, setId] = useState('');

  const ref = React.useRef(null);

  const showErrorMessage = () => {
    toast.error('Fill all the fields', {
      position: 'top-center',
    });
  };

  async function handleUpdate(event) {
    event.preventDefault();
    if (solutionname === '' || category === '') {
      showErrorMessage();
    } else {
      setSubmit(true);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/Admin/updatesolution?solutionname=${solutionname}&category=${category}&Id=${Id}`,
        );
        const { data: repsonseData } = response;
        if (repsonseData.message === 'update') {
          setShowModal(false);
          setSubmit(false);
          window.location.href = '/solutions';
        }
      } catch (error) {
        console.error('Error:', error.message);
        setSubmit(false);
      }
    }
  }

  const [, drop] = useDrop({
    accept: 'TABLE_ROW',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex; // eslint-disable-line
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'TABLE_ROW',
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.5 : 1;

  drag(drop(ref));

  return (
    <tr ref={ref} style={{ opacity }}>
      <td>
        <p className='fw-normal mb-1'>{data.solutionname}</p>
      </td>
      <td>
        <p className='fw-normal mb-1'>{data.category}</p>
      </td>
      <td>
        <i
          className='fa fa-edit'
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setShowModal(true);
            setSolutionname(data.solutionname);
            setCategory(data.category);
            setId(data.Id);
          }}
        ></i>
      </td>
      <td>
        <i
          className='fa fa-trash'
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (window.confirm('Are you sure you want to delete?')) {
              deleteSolutions(data.Id);
            }
          }}
        ></i>
      </td>

      {/* Modal for each row */}
      <MDBModal show={showModal} onHide={() => setShowModal(false)}>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalBody>
            <center><h3 style={{ color: 'black', fontFamily: 'bahnschrift', marginTop: '15px' }}>Update Solution</h3></center>
                <center>
                    <div>
                    <form>
                      <MDBInput
                        type='text'
                        placeholder="Solution Name"
                        id="solutionname"
                        name='solutionname'
                        value={solutionname}
                        onChange={(e) => setSolutionname(e.target.value)}
                        style={{ background: '#fff' }}
                      />
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        style={{ marginTop: '8px' }}
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                      >
                        <option>Select Solution Category</option>
                        <option value='Deactivation'>Deactivation</option>
                        <option value='Tuning'>Tuning</option>
                        <option value='Miscle'>Miscle</option>
                      </select>
                      <MDBBtn
                        type='submit'
                        onClick={handleUpdate}
                        style={{
                          marginTop: '8px', width: '100%', color: 'white', backgroundColor: 'black',
                        }}
                      >
                        {submit ? <MDBSpinner></MDBSpinner> : <span>Update</span>}
                      </MDBBtn>
                    </form>
                    </div>
                </center>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </tr>
  );
};

const Solutions = ({ refreshDataCallback }) => {
  const [submit, setSubmit] = useState(false);
  const [solutions, setSolutions] = useState([]);
  const [category, setCategory] = useState([]);
  const [hoverIndex] = useState(null);

  const getDetails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/Admin/getSolutions`, {
        headers: {
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data;
      setSolutions(responseData.data);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  useEffect(() => {
    getDetails();
  }, [refreshDataCallback]);

  const deleteSolutions = async (id) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/Admin/deleteSolutions?id=${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data;
      if (responseData.message === 'deleted') {
        getDetails();
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const moveRow = async (dragIndex, hoverIndexParam) => {
    const draggedRow = solutions[dragIndex];

    setSolutions((prevSolutions) => {
      const newSolutions = [...prevSolutions];
      newSolutions.splice(dragIndex, 1);
      newSolutions.splice(hoverIndexParam, 0, draggedRow);
      return newSolutions;
    });

    // Check if draggedRow and its Id property are defined
    if (draggedRow && draggedRow.Id !== undefined) {
      // Make a POST request to update sequence in the database
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/Admin/updateSequence`,
          {
            draggedId: draggedRow.Id,
            hoverId: solutions[hoverIndex].Id,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'api-key': process.env.REACT_APP_API_KEY,
            },
          },
        );

        console.log('POST Request Response:', response.data);
      } catch (error) {
        console.error('Error updating sequence:', error.message);
      }
    } else {
      console.error('Invalid draggedRow or Id:', draggedRow);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);
    const form = e.target;
    const solutionname = document.getElementById('solutionname').value;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/Admin/addsolutions?solutionname=${solutionname}&category=${category}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'api-key': process.env.REACT_APP_API_KEY,
          },
        },
      );
      const { data } = response;
      if (data.message === 'added') {
        form.reset();
        getDetails();
        setSubmit(false);
      }
    } catch (error) {
      console.error('Error:', error.message);
      setSubmit(false);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div style={{ position: 'relative', width: '100%' }}>
      <ToastContainer />
        <Sidebar />
        <div className='main-content'>
          <h3 style={{ color: 'black', marginLeft: '2%', fontFamily: 'bahnschrift' }}>Solutions</h3>

          <form onSubmit={handleSubmit}>
            <MDBRow style={{ margin: '20px' }}>
                <MDBCol md={6}>
                <MDBCard className="text-center label-left" style={{ margin: '30px', backgroundColor: '#2d353c' }}>
                <MDBCardBody>
                <MDBCardText>
                <div style={{ marginTop: '7px', marginLeft: '11px' }}>
                  <MDBInput
                    placeholder='Solution Name' id='solutionname'
                    type='text' style={{ background: '#fff' }}
                  />
                  <select
                    className='form-select'
                    aria-label='Default select example'
                    value={category}
                    style={{ marginTop: '20px' }}
                    onChange={(e) => {
                      setCategory(e.target.value);
                    }}
                  >
                    <option>Select Solution Category</option>
                    <option value='Deactivation'>Deactivation</option>
                    <option value='Tuning'>Tuning</option>
                    <option value='Miscle'>Miscle</option>
                  </select>
                </div>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-mdb-ripple-init
                  style={{
                    marginTop: '20px', marginLeft: '11px', color: 'white', backgroundColor: 'black', width: '-webkit-fill-available',
                  }}
                >
                  {submit ? <MDBSpinner></MDBSpinner> : <span>Add</span>}
                </button>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

              <MDBCol md={6}>
                    <MDBCard className="text-center label-left" style={{ margin: '30px', backgroundColor: '#2d353c' }}>
                    <MDBCardBody>
                <MDBCardText>
                <MDBTable align='middle' className='solutions_table'>
                  <MDBTableHead>
                    <tr>
                      <th scope='col' style={{ fontWeight: 'bold' }}>Solution</th>
                      <th scope='col' style={{ fontWeight: 'bold' }}>Category</th>
                      <th scope='col' style={{ fontWeight: 'bold' }}></th>
                      <th scope='col' style={{ fontWeight: 'bold' }}></th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {solutions.map((item, index) => (
                      <DraggableTableRow
                        key={item.id}
                        id={item.id}
                        hoverIndex={hoverIndex}
                        index={index}
                        moveRow={moveRow}
                        data={item}
                        deleteSolutions={deleteSolutions}
                      />
                    ))}
                  </MDBTableBody>
                </MDBTable>
                </MDBCardText>
                </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </form>
        </div>
      </div>
    </DndProvider>
  );
};

export default Solutions;
