import React from 'react';
import {
  MDBTable, MDBTableHead, MDBTableBody,
} from 'mdb-react-ui-kit';
import Sidebar from '../../parts/sidebar.jsx';

const Usershistory = () => (
    <div style={{ position: 'relative', width: '100%' }}>
      <Sidebar />
      <div className='main-content'>
        <h3 style={{ color: 'black', marginLeft: '2%', fontFamily: 'bahnschrift' }}>Users History</h3>
        <MDBTable align='middle'>
          <MDBTableHead>
            <tr>
              <th scope='col'>User Name</th>
              <th scope='col'>Info</th>
              <th scope='col'>Solution</th>
              <th scope='col'>Dated</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            <tr>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='ms-3'>
                    <p className='fw-bold mb-1'>John Doe</p>
                  </div>
                </div>
              </td>
              <td>
                <p className='fw-normal mb-1'>VAG EDCI 7CP20</p>
              </td>
              <td>
                <p className='fw-normal mb-1'>AGR</p>
              </td>
              <td>
                <p className='text-muted mb-0'>2021-10-03 2:34:20</p>
              </td>
            </tr>
            <tr>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='ms-3'>
                    <p className='fw-bold mb-1'>Alex Ray</p>
                  </div>
                </div>
              </td>
              <td>
                <p className='fw-normal mb-1'>VAG EDCI 7CP20</p>
              </td>
              <td>
                <p className='fw-normal mb-1'>AGR</p>
              </td>
              <td>
                <p className='text-muted mb-0'>2021-10-03 2:34:20</p>
              </td>
            </tr>
            <tr>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='ms-3'>
                    <p className='fw-bold mb-1'>Kate Hunington</p>
                  </div>
                </div>
              </td>
              <td>
                <p className='fw-normal mb-1'>VAG EDCI 7CP20</p>
              </td>
              <td>
                <p className='fw-normal mb-1'>AGR</p>
              </td>
              <td>
                <p className='text-muted mb-0'>2021-10-03 2:34:20</p>
              </td>
            </tr>
          </MDBTableBody>
        </MDBTable>
      </div>
    </div>
);

export default Usershistory;
