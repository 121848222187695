import React, { useEffect, useState } from 'react';
import {
  Form, Button,
} from 'react-bootstrap';
import axios from 'axios';
import {
  MDBSpinner, MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBRow, MDBCol,
} from 'mdb-react-ui-kit';
import { toast, ToastContainer } from 'react-toastify';
import Sidebar from '../../parts/sidebar.jsx';
import 'react-toastify/dist/ReactToastify.css';

const Email = () => {
  const [dealers, setDealers] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');

  const getDealers = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/Admin/getDealers`, {
        headers: {
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data;
      setDealers(responseData.data);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  useEffect(() => {
    getDealers();
  }, []);

  const showSuccessMessage = () => {
    toast.success('Email Sent', {
      position: 'top-center',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);
    const form = e.target;
    const formData = new FormData();
    formData.append('email', email);
    formData.append('subject', subject);
    formData.append('body', body);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/Admin/sendMail`, formData, {
        headers: {
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_API_KEY,
        },
      });
      const { data } = response;
      if (data.message === 'sent') {
        form.reset();
        setSubmit(false);
        showSuccessMessage();
      }
    } catch (error) {
      console.error('Error:', error.message);
      setSubmit(false);
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <ToastContainer />
      <Sidebar />
      <div className='main-content'>
        <h3 style={{ color: 'black', marginLeft: '2%', fontFamily: 'bahnschrift' }}>Broadcast</h3>

          <MDBRow style={{ margin: '20px' }}>
            <MDBCol md={6}>
                <MDBCard className="text-center label-left" style={{ margin: '30px', backgroundColor: '#2d353c' }}>
                <MDBCardBody>
                <MDBCardText>
              <form
                style={{
                  textAlign: 'left',
                }}
                onSubmit={handleSubmit}
                id="emailsender"
                method="post"
              >
                    <Form.Group className="mb-3" controlId="formName">
                      <Form.Label style={{ color: 'black' }}>Email</Form.Label>
                      <Form.Control
                        placeholder="Enter Email"
                        type="email"
                        name="to"
                        id="email"
                        onChange={(e) => { setEmail(e.target.value); }}
                        style={{ backgroundColor: '#F9F9F9' }}
                      />
                      <span id="to-error"></span>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formEmail">
                      <Form.Label style={{ color: 'black' }}>
                        Subject
                      </Form.Label>
                      <Form.Control
                        id="subject"
                        name="subject"
                        type="text"
                        onChange={(e) => { setSubject(e.target.value); }}
                        placeholder="Enter Subject"
                        style={{ backgroundColor: '#F9F9F9' }}
                      />
                      <span id="subject-error"></span>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formSubject">
                      <Form.Label style={{ color: 'black' }}>
                        Message
                      </Form.Label>
                      <textarea
                        className="form-control"
                        id="message"
                        name="message"
                        rows="5"
                        onChange={(e) => { setBody(e.target.value); }}
                        placeholder="Your Message"
                        style={{ backgroundColor: '#F9F9F9' }}
                      ></textarea>
                      <span id="message-error"></span>
                    </Form.Group>
                    <Button
                      type="submit"
                      style={{
                        backgroundColor: 'black',
                        color: 'white',
                        border: 'none',
                        fontWeight: 'bold',
                        width: '100%',
                      }}
                      block
                    >
                      {submit ? (
                        <MDBSpinner></MDBSpinner>
                      ) : (
                        <span>Send</span>
                      )}
                    </Button>
              </form>

                     </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
            <MDBCol md={6}>
                    <MDBCard className="text-center label-left" style={{ margin: '30px', backgroundColor: '#2d353c' }}>
                    <MDBCardBody>
                <MDBCardText>

                  {dealers.map((item, index) => (
                    <div key={index}>
                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{ marginBottom: '10px' }}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src={
                            'https://icons.veryicon.com/png/o/internet--web/prejudice/user-128.png'
                          }
                          style={{
                            width: '45px',
                            height: '45px',
                            borderRadius: '50px',
                            marginRight: '10px',
                          }}
                          alt=""
                        />
                        <div>
                          <a
                            href="#"
                            onClick={() => {
                              document.getElementById('email').value = item.email;
                              setEmail(item.email);
                            }}
                            style={{ textDecoration: 'none' }}
                          >
                            <div
                              style={{
                                fontSize: '17px',
                                fontWeight: 'bold',
                                color: '#fff',
                                textAlign: 'left',
                              }}
                            >
                             {item.firstname}
                            </div>
                          </a>
                          <div
                            style={{
                              fontSize: '13px',
                              textAlign: 'left',
                            }}
                          >
                            {item.email}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        height: '1px',
                        backgroundColor: '#DFE3EF',
                        marginBottom: '10px',
                      }}
                    ></div>
                  </div>
                  ))}
          </MDBCardText>
              </MDBCardBody>
            </MDBCard>
              </MDBCol>
            </MDBRow>
      </div>
    </div>
  );
};

export default Email;
